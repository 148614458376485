import { TextFieldError, UpsertableLabelValue } from 'appTypes';
import { LABEL_NAME_LIMIT, LABEL_VALUE_LIMIT } from 'constants/constraints';

export const NEW_LABEL_VALUE_PREFIX = 'new-';

const LABEL_NAME_REGEX = /^[A-Za-z0-9 _.]*$/;
const LABEL_VALUE_REGEX = /^[^>]*$/;

export function validateLabelName(
  name: string,
  existingNames: ReadonlySet<string>
): TextFieldError {
  let error: TextFieldError = null;
  if (!name) {
    error = { id: 'CannotBeBlankPeriod' };
  } else if (name.length > LABEL_NAME_LIMIT) {
    error = {
      id: 'LimitCharsPeriod',
      values: { limit: LABEL_NAME_LIMIT },
    };
  } else if (!LABEL_NAME_REGEX.test(name)) {
    error = { id: 'LabelNameError' };
  } else if (existingNames.has(name.toLowerCase())) {
    error = { id: 'LabelNameUniqueError' };
  }

  return error;
}

export function validateLabelValue(
  labelValue: Readonly<UpsertableLabelValue>,
  isNewModalEnabled = false
): TextFieldError {
  const { id, name } = labelValue;
  let error: TextFieldError = null;
  if (name.length > LABEL_VALUE_LIMIT) {
    error = {
      id: 'LimitCharsPeriod',
      values: { limit: LABEL_VALUE_LIMIT },
    };
  } else if (!LABEL_VALUE_REGEX.test(name)) {
    error = { id: 'LabelValueError' };
  } else if (
    (!name.length && isNewModalEnabled) ||
    (!name.length &&
      !isNewModalEnabled &&
      !id.startsWith(NEW_LABEL_VALUE_PREFIX))
  ) {
    error = { id: 'CannotBeBlankPeriod' };
  }

  return error;
}
