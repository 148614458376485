import Joi from 'joi';

export type ErrorRecord = Record<string, boolean>;

export function buildErrorRecord(
  thing: unknown,
  schema: Joi.Schema
): ErrorRecord {
  const errors: ErrorRecord = {};
  const { error } = schema.validate(thing, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (error) {
    error.details.forEach((d) => {
      errors[d.path[0]] = true;
    });
  }

  return errors;
}
