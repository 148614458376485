import {
  KeyboardEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { List } from 'react-virtualized';

import {
  NoRowsMessage,
  TranslateableMessage,
  useIdPrefix,
} from 'fwi-fe-components';
import { EntityId } from 'fwi-fe-types';

import { ReadonlyEntityList, UpsertableLabelValue } from 'appTypes';

import { ValueRow } from './ValueRow';
import styles from './Values.module.scss';

export const ROW_HEIGHT = 64;

export interface ValuesProps {
  height: number;
  width: number;
  errors: ReadonlyMap<EntityId, TranslateableMessage>;
  values: ReadonlyEntityList<UpsertableLabelValue>;
  disabled: boolean;
  searching: boolean;
  onAddClick(): void;
  onValueChange(value: UpsertableLabelValue): void;
  onRemoveClick(labelValueId: EntityId): void;
}

export function Values({
  height,
  width,
  errors,
  values,
  disabled,
  searching,
  onAddClick,
  onValueChange,
  onRemoveClick,
}: ValuesProps): ReactElement {
  const id = useIdPrefix();

  const rowCount = values.length;
  const [scrollToIndex, setScrollToIndex] = useState<number | undefined>(
    undefined
  );
  const prevRowCount = useRef(rowCount);
  const prevSearching = useRef(searching);
  useEffect(() => {
    const isNew = prevRowCount.current < rowCount && !prevSearching.current;
    prevRowCount.current = rowCount;
    prevSearching.current = searching;

    // the scrollToIndex should only be set when a new row has been added after
    // the add button click. so ignore the rowCount decreasing or rowCount
    // increasing after cancelling search
    setScrollToIndex(isNew ? rowCount - 1 : undefined);
  }, [rowCount, searching]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <List
      id={`${id}-values`}
      height={height}
      width={width}
      containerProps={{
        onKeyDown(event: KeyboardEvent<HTMLDivElement>) {
          if (
            event.key === 'Enter' &&
            event.target instanceof HTMLInputElement
          ) {
            event.preventDefault();
            containerRef.current = event.currentTarget;
            onAddClick();
          }
        },
      }}
      scrollToIndex={scrollToIndex}
      className={styles.container}
      rowCount={rowCount}
      rowHeight={ROW_HEIGHT}
      rowRenderer={({ key, index, style }) => {
        const value = values[index];
        return (
          <ValueRow
            key={key}
            id={`${id}-value-${index + 1}`}
            style={style}
            value={value}
            focus={index === scrollToIndex}
            error={errors.get(value.id)}
            index={index + 1}
            rowCount={rowCount}
            disabled={disabled}
            onChange={onValueChange}
            onRemoveClick={(labelValueId) => {
              // have to set to `undefined` before the `useEffect` is triggered
              // or else the last scrollToIndex will be used.
              setScrollToIndex(undefined);
              onRemoveClick(labelValueId);
            }}
            containerRef={containerRef}
          />
        );
      }}
      noRowsRenderer={() => {
        if (!searching) {
          return <></>;
        }

        return (
          <NoRowsMessage
            id={`${id}-no-matching-values`}
            messageId="NoMatchingLabelValues"
          />
        );
      }}
    />
  );
}
