import Joi from 'joi';

import {
  ALPHANUMERIC_CHARACTERS_ONLY_REGEX,
  CUSTOM_LOGIN_PREFIX_MAX,
  CUSTOM_LOGIN_PREFIX_MIN,
  CUSTOM_LOGIN_REGEXP,
  PRINTABLE_CHARACTERS_ONLY_REGEX,
} from 'constants/customer';
import {
  DEVICES_QUOTA_MAX,
  DEVICES_QUOTA_MIN,
  IFRAME_QUOTA_MAX,
  IFRAME_QUOTA_MIN,
  STORAGE_QUOTA_MAX,
  STORAGE_QUOTA_MIN,
} from 'constants/quotas';

/** An update interval must be between `00:05:00` and `23:59:59` */
export const UPDATE_INTERVAL_PATTERN =
  /^((00:(0[5-9]|[1-5][0-9]))|((0[1-9]|1[0-9]|2[0-3]):[0-5][0-9])):[0-5][0-9]$/;

const BASE_CUSTOMER_SCHEMA = {
  name: Joi.string()
    .min(1)
    .max(255)
    .regex(PRINTABLE_CHARACTERS_ONLY_REGEX)
    .required(),
  description: Joi.string().optional().allow(''),
  cpwebVersion: Joi.string().max(20).optional().allow('', null),
  cmwUrl: Joi.string().uri().max(512).optional().allow('', null),
  cpwebUrl: Joi.string().uri().max(512).optional().allow('', null),
  ifUrl: Joi.string().uri().max(512).optional().allow('', null),
  ifAlias: Joi.string()
    .regex(ALPHANUMERIC_CHARACTERS_ONLY_REGEX)
    .max(30)
    .optional()
    .allow('', null),
  internal: Joi.boolean().required(),
  trial: Joi.boolean().required(),
  approvalWorkflow: Joi.boolean().required(),
  eulaEnabled: Joi.boolean().optional(),
  feedsEnabled: Joi.boolean().optional(),
  customLoginPrefix: Joi.string()
    .regex(CUSTOM_LOGIN_REGEXP)
    .min(CUSTOM_LOGIN_PREFIX_MIN)
    .max(CUSTOM_LOGIN_PREFIX_MAX)
    .optional()
    .allow('', null)
    .messages({
      'string.min': 'CustomLoginPrefixLengthError',
      'string.max': 'CustomLoginPrefixLengthError',
      'string.pattern.base': 'InvalidPrefixError',
    }),
  sfAccountNo: Joi.string().max(255).required(),
  sfBillingAccountNo: Joi.string().max(255).required(),
  storageQuota: Joi.number()
    .integer()
    .min(STORAGE_QUOTA_MIN)
    .max(STORAGE_QUOTA_MAX)
    .required(),
  devicesQuota: Joi.number()
    .integer()
    .min(DEVICES_QUOTA_MIN)
    .max(DEVICES_QUOTA_MAX)
    .required(),
  iFramesQuota: Joi.number()
    .integer()
    .min(IFRAME_QUOTA_MIN)
    .max(IFRAME_QUOTA_MAX)
    .optional(),
  roles: Joi.object()
    .pattern(Joi.string().uuid(), Joi.number().integer().min(-1).required())
    .min(1)
    .required(),
  entitlements: Joi.object()
    .pattern(
      Joi.string().uuid(),
      Joi.number().integer().min(0).max(1).required()
    )
    .required(),
  channelsUpdateInterval: Joi.string()
    .pattern(UPDATE_INTERVAL_PATTERN)
    .required(),
  playlistUpdateInterval: Joi.string()
    .pattern(UPDATE_INTERVAL_PATTERN)
    .required(),
};
const CREATE_CUSTOMER_SCHEMA = {
  ...BASE_CUSTOMER_SCHEMA,
  admins: Joi.array().items(
    Joi.object({
      firstName: Joi.string().min(2).max(225).required(),
      lastName: Joi.string().min(2).max(255).required(),
      email: Joi.string().email({ tlds: false }).required(),
    })
  ),
};

export function validateCustomer(
  customer: unknown,
  isUpdate: boolean
): Joi.ValidationResult {
  const schema = Joi.object(
    isUpdate ? BASE_CUSTOMER_SCHEMA : CREATE_CUSTOMER_SCHEMA
  );

  return schema.validate(customer, {
    abortEarly: false,
    convert: true,
  });
}
