import { ReactElement, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button,
  LegacyButtonType,
  PlusCircleOutlineIcon,
  useIntl,
} from 'fwi-fe-components';

import {
  createLabelModalOpened,
  isMobile,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import FormattedMessage from 'components/FormattedMessage';
import { LabelsModal } from 'components/LabelsModal';
import { HEADER_BUTTON } from 'constants/branding';
import { useSplitEnabled } from 'hooks/useSplit';
import {
  ADMIN_GROUPS_NEW,
  ADMIN_LABELS_NEW,
  ADMIN_USERS_NEW,
  isAdminGroups,
  isAdminUsers,
} from 'utils/routes';

import AddUser from './AddUser';
import BulkUpload from './BulkUpload';
import NewContentButton from './NewContentButton';

export interface AdminNewButtonProps {
  id: string;
}

export default function AdminNewButton({
  id,
}: AdminNewButtonProps): ReactElement {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const mobile = useAppSelector(isMobile);
  const bulkUpload = useSplitEnabled('DS_BULK_USER_UPLOAD');
  const history = useHistory();
  const [isVisible, setLabelsModalVisible] = useState(false);
  const showLabelsModal = useCallback(() => setLabelsModalVisible(true), []);
  const hideLabelsModal = useCallback(() => setLabelsModalVisible(false), []);
  const showSuperModal = useSplitEnabled('DS_ADMIN_LABEL_MODAL');

  const { pathname } = history.location;
  if (!bulkUpload || !isAdminUsers(pathname)) {
    let icon: ReactElement | null = null;
    let label: string | undefined;
    let children = <PlusCircleOutlineIcon theme="inherit" />;
    let btnType: LegacyButtonType = 'icon';
    if (mobile) {
      label = intl.formatMessage({ id: 'New' });
    } else {
      icon = children;
      btnType = 'text';
      children = <FormattedMessage messageId="New" />;
    }

    return (
      <>
        <LabelsModal visible={isVisible} onRequestClose={hideLabelsModal} />
        <Button
          id={id}
          aria-label={label}
          btnType={btnType}
          icon={icon}
          onClick={() => {
            if (isAdminUsers(pathname)) {
              history.push(ADMIN_USERS_NEW);
            } else if (isAdminGroups(pathname)) {
              history.push(ADMIN_GROUPS_NEW);
            } else {
              showSuperModal
                ? history.push(ADMIN_LABELS_NEW)
                : showLabelsModal();
              dispatch(createLabelModalOpened());
            }
          }}
          className={HEADER_BUTTON}
        >
          {children}
        </Button>
      </>
    );
  }

  return (
    <NewContentButton id={id}>
      <AddUser />
      <BulkUpload />
    </NewContentButton>
  );
}
