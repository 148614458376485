import cn from 'classnames';
import { kebabCase } from 'lodash';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import {
  DropdownMenu,
  GridIcon,
  MenuItemSeparator,
  Subheader,
  useIntl,
} from 'fwi-fe-components';

import {
  bentoBoxViewed,
  getAvailableModulesAndLinks,
  isLoadingCurrentUser,
  isMobile,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import { UserModuleOrLink } from 'appTypes';
import FormattedMessage from 'components/FormattedMessage';
import LoadingMenuItem from 'components/LoadingMenuItem';
import { USER_LINK_ICONS } from 'constants/userLinks';
import { useSplitEnabled } from 'hooks/useSplit';

import ModuleMenuItem from './ModuleMenuItem';
import styles from './ModuleNavigation.module.scss';

const getId = (moduleId: string): string => `module-${kebabCase(moduleId)}`;

export default function ModuleNavigation(): ReactElement {
  const intl = useIntl();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isReportingEnabled = useSplitEnabled('DS_REPORTS');
  const loading = useAppSelector(isLoadingCurrentUser);
  const { links, modules } = useAppSelector(getAvailableModulesAndLinks);
  const mobile = useAppSelector(isMobile);

  const getModules = (): UserModuleOrLink[] => {
    if (!isReportingEnabled) {
      return modules.filter((module) => module.moduleId !== 'Reporting');
    }

    return modules;
  };

  const moduleLinks = getModules();
  const isModulesVisible = moduleLinks.length > 0;
  const isLinksVisible = links.length > 0;

  return (
    <DropdownMenu
      id="module-navigation"
      aria-label={intl.formatMessage({ id: 'Modules' })}
      className={cn(styles.dropdown, {
        [styles.dropdownMobile]: mobile,
        [styles.dropdownDesktop]: !mobile,
      })}
      menuClassName={styles.menu}
      menuOnlyClassName={styles.menuDesktop}
      isSheet={mobile}
      isOnDarkBackground
      buttonType="icon"
      buttonChildren={<GridIcon />}
      multiline
      onVisibilityChange={(nextVisible: boolean) => {
        if (nextVisible) {
          dispatch(bentoBoxViewed());
        }
      }}
      defaultFocusIndex={moduleLinks.findIndex(({ href }) =>
        pathname.startsWith(href)
      )}
    >
      {loading && (
        <LoadingMenuItem id="loading-user-modules" className="md-full-width" />
      )}
      {moduleLinks.map((props) => (
        <ModuleMenuItem
          {...props}
          id={getId(props.moduleId)}
          key={props.moduleId}
          icon={USER_LINK_ICONS[props.moduleId]}
        />
      ))}
      {isModulesVisible && isLinksVisible && (
        <>
          <MenuItemSeparator className={styles.separator} />
          <Subheader
            className={styles.subheader}
            primaryText={<FormattedMessage messageId="Dashboard.QuickAccess" />}
          />
        </>
      )}
      {links.map((props) => (
        <ModuleMenuItem
          {...props}
          id={getId(props.moduleId)}
          key={props.moduleId}
          icon={USER_LINK_ICONS[props.moduleId]}
        />
      ))}
    </DropdownMenu>
  );
}
