import { EntityState } from '@reduxjs/toolkit';

import {
  EntityId,
  EntityMetadata,
  PaginationRequestOptions,
  ValuesOf,
} from 'fwi-fe-types';

import { EntityRecord, IdNameReference, NormalizeEntity } from './common';

export const LabelAssignmentType = {
  EXPLICIT: 'explicit',
  INHERITED: 'inherited',
} as const;
export type LabelAssignmentType = ValuesOf<typeof LabelAssignmentType>;

export interface LabelValueEntity extends EntityMetadata {
  /**
   * The unique id for this value.
   */
  id: EntityId;

  /**
   * The id for the label that "owns" this value.
   */
  labelId: EntityId;

  /**
   * The name for this value.
   */
  name: string;

  /**
   * If the label value is a FeedGroup, this will be the subheader name.
   */
  description?: string;
}

/**
 * We technically only support the text type right now.
 */
export const LabelInputType = {
  Text: 'text',
  Dropdown: 'dropdown',
} as const;
export type LabelInputType = ValuesOf<typeof LabelInputType>;

export interface AssignedDevice extends IdNameReference {
  labelValueIds: EntityId[];
  tableId: EntityId;
}

export interface LabelEntity extends EntityMetadata {
  /**
   * The unique id for the label.
   */
  id: EntityId;

  /**
   * The name for the label.
   */
  name: string;

  /**
   * The type of the label.
   */
  inputType: LabelInputType;

  /**
   * Boolean if this is a default label that was generated automatically.
   */
  isDefault: boolean;

  /**
   * A list of values associated to this label.
   */
  values: LabelValueEntity[];

  /**
   * The description for the label.
   */
  description: string;

  /**
   * Note: This list will only be populated after fetching the label
   * specifically with {@link fetchLabel}
   */
  devices?: AssignedDevice[];

  /**
   * Boolean if this is an internal system label.
   */
  isSystemLabel: boolean;

  /**
   * The number of devices that have this label assigned.
   */
  deviceCount?: number;
}

/**
 * A label assigned to a device
 */
export interface AssignedLabelEntity {
  /**
   * The parent entity id for this value.
   */
  assignmentParentId: EntityId | null;

  /**
   * Indicates if this label was assigned explicity or inherited.
   */
  assignmentType: LabelAssignmentType;

  /**
   * The unique id for this value.
   */
  entityId: EntityId;

  /**
   * Boolean if this is a default label that was generated automatically.
   */
  isDefault: boolean;

  /**
   * The id for the label that "owns" this value.
   */
  labelId: EntityId;

  /**
   * The name for the label that "owns" this value.
   */
  labelName: string;

  /**
   * The ID for this label value.
   */
  labelValueId: EntityId;

  /**
   * The name for this label value.
   */
  labelValueName: string;

  /**
   * The device id that this label is assigned to.
   */
  tableId: EntityId;
}

export type NormalizedLabelEntity = NormalizeEntity<LabelEntity, 'values'>;
export type LabelSortKey = 'name' | 'valueCount' | 'deviceCount';
export interface LabelsSearchOptions
  extends PaginationRequestOptions<LabelSortKey> {
  search: string;
}

export interface LabelsState
  extends EntityState<NormalizedLabelEntity>,
    LabelsSearchOptions {
  loading: boolean;
  loadingIds: EntityId[];
  markedForDeletion: EntityId[];
  isSorted: boolean;
}

export type LabelAppId = 'Library' | 'DeviceManagement';

export type LabelValuesState = EntityState<LabelValueEntity>;

/**
 * This is the result returned when calling `normalize()` on a label entity. All
 * labels found will be placed in the `labels` key because the
 * {@link LABEL_ENTITY} uses the `'labels'` identifier while all label values
 * will be placed in the `labelValues` key because the
 * {@link LABEL_VALUE_ENTITY} uses the `'labelValues'` identifier.
 *
 * Note: The result from `normalize()` is really: `Partial<NormalizedLabels>`
 * since the child records will only exist if there is at least one of those
 * entities found.
 */
export interface NormalizedLabels {
  labels: EntityRecord<NormalizedLabelEntity>;
  labelValues: EntityRecord<LabelValueEntity>;
}

/**
 * When a new value is added to a label, it only is an object with a name.
 */
export interface NewLabelValueEntity {
  name: string;
}

export interface UpsertableLabelValue extends NewLabelValueEntity {
  id: EntityId;
  visible?: boolean; // Make required when DS_ADMIN_LABEL_MODAL is removed
  originalName?: string; // Make required when DS_ADMIN_LABEL_MODAL is removed
}

export type LabelValueUpsertList = (
  | NewLabelValueEntity
  | UpsertableLabelValue
)[];

/**
 * Both new labels and existing labels share this same data when sent to
 * the upsert endpoint.
 */
export interface NewLabelEntity {
  name: string;
  description: string;
  inputType: LabelInputType;
  values: NewLabelValueEntity[];
  valueIdsToDelete: EntityId[];
}

export interface LabelEntityPatch extends NewLabelEntity {
  id: EntityId;
  values: LabelValueUpsertList;
}

export type UpsertableLabelEntity =
  | (NewLabelEntity & { id: '' })
  | LabelEntityPatch;

export type LabelSortType = 'asc' | 'desc' | 'most' | 'least';
