import Joi from 'joi';

const ALERT_EMAIL_RULE = Joi.string()
  .min(1)
  .email({ minDomainSegments: 2, tlds: false });

/**
 * Checks if the provided email string is valid.
 *
 * @returns true if the email is valid
 */
export function validateAlertEmail(email: string): boolean {
  return !!ALERT_EMAIL_RULE.validate(email, {
    abortEarly: false,
    allowUnknown: true,
  }).error;
}
