import cn from 'classnames';
import { ReactElement } from 'react';

import { Button, MinusCircleOpenSmIcon, useIntl } from 'fwi-fe-components';

import FormattedMessage from 'components/FormattedMessage';

import styles from './UserAccount.module.scss';

export interface UserAccountProps {
  id: string;
  removing: boolean;
  removable: boolean;
  companyId: string;
  companyName: string;
  onRemoveToggle(companyId: string, removing: boolean): void;
}

export default function UserAccount({
  id,
  removing,
  removable,
  companyId,
  companyName,
  onRemoveToggle,
}: UserAccountProps): ReactElement {
  const intl = useIntl();
  return (
    <li className={styles.item}>
      <span className={cn(styles.name, removing && styles.lineThrough)}>
        {companyName}
      </span>
      {removable && (
        <Button
          id={`${id}-remove`}
          aria-label={
            removing ? undefined : intl.formatMessage({ id: 'Remove' })
          }
          btnType={removing ? 'text' : 'icon'}
          theme="clear"
          onClick={() => {
            onRemoveToggle(companyId, removing);
          }}
          className={styles.button}
        >
          {removing ? (
            <FormattedMessage
              messageId="RestoreAccount"
              className={styles.text}
            />
          ) : (
            <MinusCircleOpenSmIcon theme="error" />
          )}
        </Button>
      )}
    </li>
  );
}
