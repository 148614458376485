import cn from 'classnames';
import type { FocusEvent, ReactElement } from 'react';

import {
  CursorTextIcon,
  ExternalLink,
  FormattedMessage,
  IconSeparator,
  TextField,
  ToggleButtonGroup,
  useIdPrefix,
} from 'fwi-fe-components';

import { isMobile, useAppSelector } from 'appState';
import { LabelInputType, TextFieldError } from 'appTypes';
import { Heading } from 'components/Heading';
import { COMMUNITY_LABELS_HREF } from 'constants/community';
import { LABEL_NAME_LIMIT } from 'constants/constraints';

import { HelpText } from './HelpText';
import styles from './LabelSection.module.scss';

export interface LabelSectionProps {
  error: TextFieldError;
  editing: boolean;
  disabled: boolean;
  labelName: string;
  labelInputType: LabelInputType;
  updateLabelName(labelName: string): void;
  updateLabelInputType(labelInputType: LabelInputType): void;
}

export function LabelSection({
  error,
  editing,
  disabled,
  labelName,
  labelInputType,
  updateLabelName,
  updateLabelInputType,
}: LabelSectionProps): ReactElement {
  const id = useIdPrefix();
  const mobile = useAppSelector(isMobile);
  const fieldId = `${id}-label`;
  const labelTypeId = `${id}-label-type`;
  const labelTypeLabelId = `${labelTypeId}-label`;

  return (
    <div
      className={cn(
        styles.container,
        !mobile && styles.bordered,
        mobile && styles.padded
      )}
    >
      <Heading htmlFor={fieldId} component="label" messageId="Label" />
      <TextField
        id={fieldId}
        name="name"
        block={editing}
        disabled={disabled || editing}
        readOnly={editing}
        required={!editing}
        autoComplete="off"
        maxCharLimit={LABEL_NAME_LIMIT}
        value={labelName}
        error={!!error}
        errorText={error}
        showInlineIndicatorIcon={!editing}
        className={styles.field}
        inputClassName={styles.editing}
        onChange={updateLabelName}
        onBlur={(event: FocusEvent<HTMLInputElement>) => {
          updateLabelName(event.currentTarget.value.trim());
        }}
      />
      <Heading id={labelTypeLabelId} component="div" messageId="Type" />
      <ToggleButtonGroup
        id={labelTypeId}
        aria-labelledby={labelTypeLabelId}
        value={labelInputType}
        onChange={updateLabelInputType}
        values={[
          {
            value: LabelInputType.Text,
            children: (
              <IconSeparator label={LabelInputType.Text} iconBefore>
                <CursorTextIcon theme="inherit" />
              </IconSeparator>
            ),
          },
        ]}
        iconButtons
      />
      <HelpText
        messageId="MoreInputTypesComingSoon"
        messageValues={{
          communityLink: (
            <ExternalLink
              id={`${id}-community-link`}
              key="community-link"
              href={COMMUNITY_LABELS_HREF}
            >
              <FormattedMessage messageId="Community" />
            </ExternalLink>
          ),
        }}
      />
    </div>
  );
}
