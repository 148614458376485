import Joi from 'joi';

import type {
  DialogValidation,
  EmbedDomain,
  TranslateableMessage,
} from 'appTypes';

// Requires that input be a well-formed url with a domain no longer than 63 characters.
const VALID_DOMAIN_REGEXP =
  /^(https?:\/\/)(www.)?[A-z0-9][A-z0-9-]{1,61}[A-z0-9](\.[A-z0-9]+)+$/;

export const VALID_DOMAIN_RULE = Joi.string()
  .regex(RegExp(VALID_DOMAIN_REGEXP))
  .allow('');

export function validateShareUrl(
  value: string,
  domains: EmbedDomain[]
): DialogValidation {
  let error: TranslateableMessage | null = null;
  let disableConfirm: boolean | undefined;
  const isValid = !VALID_DOMAIN_RULE.validate(value).error;
  if (
    domains.some(
      ({ domain }) => domain.toLocaleUpperCase() === value.toLocaleUpperCase()
    )
  ) {
    error = { id: 'DuplicateDomainError' };
  } else if (value.trim().length < 1) {
    disableConfirm = true;
  } else if (!isValid) {
    error = { id: 'Invalid' };
  }
  return { error, value, disableConfirm };
}
