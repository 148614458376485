import { nanoid } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { useCallback, useMemo, useReducer, useState } from 'react';

import { TranslateableMessage } from 'fwi-fe-components';
import { EntityId } from 'fwi-fe-types';

import {
  addToast,
  getLabelById,
  getLabelNames,
  getLabelValuesByLabelIds,
  labelCreated,
  upsertLabel,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import {
  EntityAction,
  LabelInputType,
  LabelValueUpsertList,
  ReadonlyEntityList,
  TextFieldError,
  UpsertableLabelValue,
} from 'appTypes';
import { LABEL_VALUE_LIMIT } from 'constants/constraints';
import {
  NEW_LABEL_VALUE_PREFIX,
  validateLabelName,
  validateLabelValue,
} from 'utils/validation';

interface State {
  searchText: string;
  labelName: string;
  labelNameError: TextFieldError;
  labelInputType: LabelInputType;
  deletedIds: ReadonlySet<EntityId>;

  /**
   * This is actually a combination of NEW values that will have an id prefixed
   * with {@link NEW_LABEL_VALUE_PREFIX} and any existing values that were
   * modified.
   */
  changedValues: ReadonlyMap<EntityId, string>;
  validationErrors: ReadonlyMap<string, TranslateableMessage>;
}

export type UpdateLabelValue = (labelValue: UpsertableLabelValue) => void;

interface ReturnValue {
  searchText: string;
  labelName: string;
  labelNameError: TextFieldError;
  labelInputType: LabelInputType;
  errors: ReadonlyMap<string, TranslateableMessage>;

  /**
   * @returns `true` if the save was successful
   */
  save(): Promise<boolean>;
  saving: boolean;
  changed: boolean;
  editing: boolean;
  values: ReadonlyEntityList<UpsertableLabelValue>;

  addValue(): void;
  removeValue(labelValueId: EntityId): void;
  setSearchText(searchText: string): void;
  updateLabelName(labelName: string): void;
  updateLabelInputType(labelInputType: LabelInputType): void;
  updateLabelValue: UpdateLabelValue;
}

type Action =
  | { type: 'add' }
  | { type: 'remove'; payload: EntityId }
  | { type: 'search' | 'labelName'; payload: string }
  | { type: 'labelInputType'; payload: LabelInputType }
  | { type: 'labelValue'; payload: UpsertableLabelValue };

const INITIAL_STATE: State = {
  searchText: '',
  labelName: '',
  labelNameError: null,
  labelInputType: LabelInputType.Text,
  deletedIds: new Set(),
  changedValues: new Map(),
  validationErrors: new Map(),
};

interface ValidateUniqueLabelNameOptions {
  id: EntityId;
  name: string;
  errors: Map<EntityId, TranslateableMessage>;
  existingValueNames: Map<string, EntityId>;
}

const validateUniqueLabelName = ({
  id,
  name,
  errors,
  existingValueNames,
}: ValidateUniqueLabelNameOptions): void => {
  if (!name) {
    return;
  }

  const lowerName = name.toLowerCase();
  const existingValueId = existingValueNames.get(lowerName);
  if (existingValueId) {
    if (!errors.has(id)) {
      errors.set(id, { id: 'LabelValueUniqueError' });
    }

    if (!errors.has(existingValueId)) {
      errors.set(existingValueId, { id: 'LabelValueUniqueError' });
    }
  } else {
    existingValueNames.set(lowerName, id);
  }
};

export function useUpsertLabel(labelId: EntityId): ReturnValue {
  const label = useAppSelector((state) => getLabelById(state, labelId));
  const editing = !!label;

  // Need to validate the label names while the user types to prevent
  // duplicates. There might be 3k+ labels, so need to use a `Set` for quick
  // lookups on label name and use an `isEqual` selector so the `Set` isn't
  // remade each render.
  const existingLabelNames = useAppSelector(
    (state) => getLabelNames(state, false),
    isEqual
  );
  // label names are unique ignoring case
  const existingLabelNamesSet = useMemo(
    () => new Set(existingLabelNames.map((name) => name.toLowerCase())),
    [existingLabelNames]
  );

  // Values are a bit trickier since there will be 10k+ AND need to check for
  // changes before allowing the done button to be enabled. Use a `Map` so that
  // we can quickly check if a value has been reset back to its default value or
  // not by the provided `labelValueId`. When a value is set back to the default
  // value, it should be removed from the `changedValues` so it isn't sent in
  // the update request.
  const defaultValues = useAppSelector(
    (state) => getLabelValuesByLabelIds(state, label?.values ?? []),
    isEqual
  );
  const defaultValuesMap = useMemo(() => {
    const map = new Map<EntityId, string>();
    defaultValues.forEach(({ id, name }) => {
      map.set(id, name);
    });

    return map;
  }, [defaultValues]);

  const resolveLabelValueUpdates = ({
    state,
    action,
  }: {
    state: State;
    action: {
      type: 'labelValue';
      payload: UpsertableLabelValue;
    };
  }): {
    changedValues: Map<string, string>;
    errors: Map<string, TranslateableMessage>;
  } => {
    const errors = new Map(state.validationErrors);
    const changedValues = new Map(state.changedValues);

    // there should really only be newlines when the user copy/pastes.
    // each newline that has at least one character after trimming
    // whitespace should be added to the list of values.
    const values = action.payload.name.split(/\r?\n/);
    const lines = values.length;
    const isPaste = lines > 1;
    for (let i = 0; i < lines; i += 1) {
      let { id } = action.payload;
      if (i !== 0) {
        id = `${NEW_LABEL_VALUE_PREFIX}${nanoid()}`;
      }

      // while pasting, treat each line like a blurred value and remove
      // all whitespace
      let name = isPaste ? values[i].trim() : values[i].trimStart();

      if (name || !isPaste) {
        name = resolveLabelError({
          error: validateLabelValue({ id, name }),
          name,
          id,
          errors,
        });

        if (defaultValuesMap.get(id) === name) {
          changedValues.delete(id);
        } else {
          changedValues.set(id, name);
        }
      }
    }

    return { changedValues, errors };
  };

  const resolveLabelError = ({
    error,
    name,
    id,
    errors,
  }: {
    error: TextFieldError;
    name: string;
    id: string;
    errors: Map<string, TranslateableMessage>;
  }): string => {
    if (error) {
      // this functionality had to be removed from the `TextField`
      // since it would prevent the full clipboard data from being
      // applied.
      if (error.id === 'LimitChars') {
        name = name.substring(0, LABEL_VALUE_LIMIT);
      }

      errors.set(id, error);
    } else {
      errors.delete(id);
    }

    return name;
  };

  const [state, dispatch] = useReducer(
    function reducer(state: State, action: Action): State {
      switch (action.type) {
        case 'add': {
          const changedValues = new Map(state.changedValues);
          changedValues.set(`${NEW_LABEL_VALUE_PREFIX}${nanoid()}`, '');

          return { ...state, changedValues };
        }
        case 'remove': {
          const labelValueId = action.payload;
          const errors = new Map(state.validationErrors);
          const deletedIds = new Set(state.deletedIds);
          const changedValues = new Map(state.changedValues);

          errors.delete(labelValueId);
          changedValues.delete(labelValueId);
          if (!labelValueId.startsWith(NEW_LABEL_VALUE_PREFIX)) {
            deletedIds.add(labelValueId);
          }

          return {
            ...state,
            deletedIds,
            changedValues,
            validationErrors: errors,
          };
        }
        case 'search':
          return { ...state, searchText: action.payload };
        case 'labelName': {
          const labelName = action.payload.trimStart();
          const labelNameError = validateLabelName(
            labelName,
            existingLabelNamesSet
          );

          return {
            ...state,
            labelName,
            labelNameError,
          };
        }
        case 'labelInputType':
          return { ...state, labelInputType: action.payload };
        case 'labelValue': {
          // there should really only be newlines when the user copy/pastes.
          // each newline that has at least one character after trimming
          // whitespace should be added to the list of values.
          const { changedValues, errors } = resolveLabelValueUpdates({
            state,
            action,
          });

          return {
            ...state,
            changedValues,
            validationErrors: errors,
          };
        }
        default:
          return state;
      }
    },
    INITIAL_STATE,
    () => ({
      searchText: '',
      labelName: label?.name ?? '',
      labelNameError: null,
      labelInputType: label?.inputType ?? LabelInputType.Text,
      deletedIds: new Set<string>(),
      changedValues: new Map<EntityId, string>(),
      validationErrors: new Map<string, TranslateableMessage>(),
    })
  );
  const {
    searchText,
    labelName,
    labelNameError,
    labelInputType,
    deletedIds,
    changedValues,
    validationErrors,
  } = state;

  const { values, errors, isValidChangedValues } = useMemo(() => {
    // an **ordered** list of values starting with the existing values and will
    // use the changed value (if exists). this list will also be filtered based
    // on the search text.
    const values: UpsertableLabelValue[] = [];
    const errors = new Map(validationErrors);

    // Use a lookup of `name` -> `first value id` so that we can correctly add
    // the "duplicate value" error to the original value as well.
    const existingValueNames = new Map<string, EntityId>();
    defaultValues.forEach((value) => {
      const { id } = value;
      const name = changedValues.get(id) ?? value.name;
      if (deletedIds.has(id)) {
        return;
      }

      validateUniqueLabelName({
        id,
        name,
        errors,
        existingValueNames,
      });

      if (!searchText || name.toLowerCase().includes(searchText)) {
        values.push({ id, name });
      }
    });

    // This is used to check if there is at least one valid NEW or CHANGED value
    // to allow the done button to become enabled.
    let isValidChangedValues = !editing;
    [...changedValues.entries()].forEach(([valueId, name]) => {
      if (name) {
        isValidChangedValues = true;
      }

      if (!valueId.startsWith(NEW_LABEL_VALUE_PREFIX)) {
        return;
      }

      validateUniqueLabelName({
        id: valueId,
        name,
        errors,
        existingValueNames,
      });

      if (!searchText || name.toLowerCase().includes(searchText)) {
        values.push({ id: valueId, name });
      }
    });

    return {
      values,
      errors,
      isValidChangedValues,
    };
  }, [
    changedValues,
    defaultValues,
    deletedIds,
    editing,
    searchText,
    validationErrors,
  ]);

  const changed =
    !labelNameError &&
    !errors.size &&
    (deletedIds.size > 0 || (labelName !== '' && isValidChangedValues));

  const addValue = useCallback(() => {
    dispatch({ type: 'add' });
  }, []);
  const removeValue = useCallback((valueId: EntityId) => {
    dispatch({ type: 'remove', payload: valueId });
  }, []);

  const setSearchText = useCallback((searchText: string) => {
    dispatch({ type: 'search', payload: searchText });
  }, []);

  const updateLabelName = useCallback((labelName: string) => {
    dispatch({ type: 'labelName', payload: labelName });
  }, []);
  const updateLabelInputType = useCallback((inputType: LabelInputType) => {
    dispatch({ type: 'labelInputType', payload: inputType });
  }, []);

  const updateLabelValue = useCallback<UpdateLabelValue>((labelValue) => {
    dispatch({ type: 'labelValue', payload: labelValue });
  }, []);

  const dispatchAction = useAppDispatch();
  const [saving, setSaving] = useState(false);
  const save = useCallback(async () => {
    setSaving(true);

    const values: LabelValueUpsertList = [];
    [...changedValues.entries()].forEach(([labelValueId, name]) => {
      if (labelValueId.startsWith(NEW_LABEL_VALUE_PREFIX)) {
        // For some reason we consider new values "valid" if they are the
        // empty string and don't prevent submissions. Empty values are
        // invalid though, so we need to remove them before sending to the
        // BE.
        if (name) {
          values.push({ name });
        }
      } else {
        values.push({ id: labelValueId, name });
      }
    });
    const valueIdsToDelete = [...deletedIds];

    const result = await dispatchAction(
      upsertLabel({
        id: labelId === EntityAction.New ? '' : labelId,
        name: labelName,
        description: '',
        inputType: labelInputType,
        values,
        valueIdsToDelete,
      })
    );

    setSaving(false);
    if (upsertLabel.rejected.match(result)) {
      dispatchAction(
        addToast({
          messageId: editing ? 'UpdateLabelFailure' : 'CreateLabelFailure',
        })
      );
      return false;
    }

    dispatchAction(
      addToast({
        messageId: editing ? 'UpdateLabelSuccess' : 'CreateLabelSuccess',
      })
    );
    if (!labelId) {
      dispatchAction(labelCreated());
    }

    return true;
  }, [
    changedValues,
    deletedIds,
    dispatchAction,
    editing,
    labelId,
    labelInputType,
    labelName,
  ]);

  return {
    save,
    saving,
    values,
    changed,
    editing,
    searchText,
    errors,
    labelName,
    labelNameError,
    labelInputType,
    addValue,
    removeValue,
    setSearchText,
    updateLabelName,
    updateLabelInputType,
    updateLabelValue,
  };
}
