import type { ReactElement } from 'react';

import { Dialog } from 'fwi-fe-components';
import { EntityId } from 'fwi-fe-types';

import { isMobile, useAppSelector } from 'appState';

import { LabelsModalContent } from './LabelsModalContent';

export interface LabelsModalProps {
  /**
   * When this is omitted or set to the empty string, the label modal
   * will allow for creating labels instead of editing an existing one
   */
  labelId?: EntityId;
  visible: boolean;
  onRequestClose(): void;
}

export function LabelsModal({
  labelId = '',
  visible,
  onRequestClose,
}: LabelsModalProps): ReactElement {
  const mobile = useAppSelector(isMobile);

  return (
    <Dialog
      id="labels-modal"
      aria-labelledby="labels-modal-title"
      visible={visible}
      onRequestClose={onRequestClose}
      size={Dialog.Size.EXTRA_LARGE}
      type={mobile ? Dialog.Type.FULL_PAGE : Dialog.Type.CENTERED}
    >
      <LabelsModalContent labelId={labelId} onRequestClose={onRequestClose} />
    </Dialog>
  );
}
