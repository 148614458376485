import { ReactElement, ReactNode } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { IntlHookProvider } from 'fwi-fe-components';

import { messages } from 'messages';

const locale = 'en';

export interface IntlProviderProps {
  children: ReactNode;
}

export function IntlProvider({ children }: IntlProviderProps): ReactElement {
  return (
    <ReactIntlProvider locale={locale} messages={messages[locale]}>
      <IntlHookProvider>{children}</IntlHookProvider>
    </ReactIntlProvider>
  );
}
