import type { ReactElement } from 'react';

import {
  DialogCloseButton,
  DialogHeader,
  DialogTitle,
  FormattedMessage,
  VirtualizedSplitViewSearch,
  useIdPrefix,
} from 'fwi-fe-components';

export interface LabelsModalHeaderProps {
  saving: boolean;
  editing: boolean;
  onSearchChange(searchText: string): void;
  onRequestClose(): void;
}

export function LabelsModalHeader({
  saving,
  editing,
  onSearchChange,
  onRequestClose,
}: LabelsModalHeaderProps): ReactElement {
  const id = useIdPrefix();
  return (
    <>
      <DialogHeader>
        <DialogTitle id={`${id}-title`}>
          <FormattedMessage
            messageId={editing ? 'EditLabel' : 'CreateNewLabel'}
          />
        </DialogTitle>
        <DialogCloseButton
          id={`${id}-x`}
          onClick={onRequestClose}
          disabled={saving}
        />
      </DialogHeader>
      <VirtualizedSplitViewSearch
        id={`${id}-search`}
        disabled={saving}
        onSearchChange={onSearchChange}
      />
    </>
  );
}
