import Joi from 'joi';

import { GROUP_NAME_LIMIT } from 'constants/constraints';

import { ErrorRecord, buildErrorRecord } from './buildErrorRecord';

const GROUP_SCHEMA = Joi.object<{ name: string }>({
  name: Joi.string()
    .regex(/^[^,]+$/)
    .max(GROUP_NAME_LIMIT)
    .required(),
});

export function validateGroup(group: Readonly<{ name: string }>): ErrorRecord {
  return buildErrorRecord(group, GROUP_SCHEMA);
}
