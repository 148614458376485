import {
  CSSProperties,
  FocusEvent,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';

import {
  FlatButton,
  MinusCircleOutlineIcon,
  TextField,
  TranslateableMessage,
  useIntl,
} from 'fwi-fe-components';
import { EntityId } from 'fwi-fe-types';

import { UpsertableLabelValue } from 'appTypes';

import styles from './ValueRow.module.scss';
import { UpdateLabelValue } from './useUpsertLabel';

export interface ValueRowProps {
  id: string;
  style: CSSProperties;
  value: UpsertableLabelValue;
  error: TranslateableMessage | undefined;
  focus: boolean;

  /**
   * Note: This should start from `1` instead of `0`. It is used for the
   * `aria-posinset` attribute.
   */
  index: number;

  /**
   * This is used for the `aria-setsize` attribute.
   */
  rowCount: number;
  disabled: boolean;
  onChange: UpdateLabelValue;
  containerRef: RefObject<HTMLDivElement>;
  onRemoveClick(labelValueId: EntityId): void;
}

type TextFieldInstance = { getField(): HTMLInputElement | null } | null;

export function ValueRow({
  id,
  style,
  value,
  error,
  focus,
  index,
  rowCount,
  disabled,
  onChange,
  containerRef,
  onRemoveClick,
}: ValueRowProps): ReactElement {
  const intl = useIntl();
  const labelValueId = value.id;
  const valueName = value.name;

  const ref = useRef<TextFieldInstance>(null);

  // this hook is used to focus a newly created text field if it was created by
  // the Enter key on another text field
  useEffect(() => {
    const field = ref.current?.getField();
    const activeElement = document.activeElement;
    const container = containerRef.current;
    if (!field || !container || !activeElement || !focus) {
      return;
    }

    field.focus();
  }, [containerRef, focus]);

  return (
    <div
      aria-posinset={index}
      aria-setsize={rowCount}
      id={`${id}-container`}
      style={style}
      className={styles.container}
    >
      <TextField
        key={labelValueId}
        aria-label={intl.formatMessage({ id: 'LabelValue' })}
        autoComplete="off"
        id={id}
        ref={ref}
        error={!!error}
        errorText={error && <FormattedMessage {...error} />}
        disabled={disabled}
        value={valueName}
        onChange={(name: string) => {
          onChange({ id: labelValueId, name });
        }}
        onBlur={(event: FocusEvent<HTMLInputElement>) =>
          onChange({
            id: labelValueId,
            name: event.currentTarget.value.trim(),
          })
        }
      />
      <FlatButton
        id={`${id}-remove`}
        aria-label={intl.formatMessage({ id: 'Remove' })}
        buttonType="icon"
        disabled={disabled}
        onClick={() => {
          onRemoveClick(labelValueId);
        }}
      >
        <MinusCircleOutlineIcon theme="error" />
      </FlatButton>
    </div>
  );
}
