import { ReactElement, useCallback, useState } from 'react';

import {
  FlatButton,
  FormattedMessage,
  PlusCircleOutlineIcon,
  TranslateableMessage,
  useIdPrefix,
  useResizeObserver,
} from 'fwi-fe-components';
import { EntityId } from 'fwi-fe-types';

import { isMobile, useAppSelector } from 'appState';
import { ReadonlyEntityList, UpsertableLabelValue } from 'appTypes';
import { Heading } from 'components/Heading';

import { HelpText } from './HelpText';
import { ROW_HEIGHT, Values } from './Values';
import styles from './ValuesSection.module.scss';

const DIALOG_WIDTH = 800;
const DIALOG_PADDING = 32;
const DIALOG_VERTICAL_MARGIN = 24;
const DIALOG_HEADER_HEIGHT = 48;
const DIALOG_FOOTER_HEIGHT = 61;
const HEADING_HEIGHT = 50;
const ADD_BUTTON_HEIGHT = 40 + 8; // 8px for margin
const HELP_TEXT_HEIGHT = 28;
const NO_RESULTS_HEIGHT = 40;

interface HeightOptions {
  mobile: boolean;
  rowCount: number;
  searching: boolean;
  isHelpTextVisible: boolean;
}

const getHeight = ({
  mobile,
  rowCount,
  searching,
  isHelpTextVisible,
}: HeightOptions): number => {
  let maxHeight =
    window.innerHeight -
    DIALOG_HEADER_HEIGHT * 2 -
    DIALOG_PADDING * 2 -
    DIALOG_FOOTER_HEIGHT -
    HEADING_HEIGHT;

  if (!searching) {
    maxHeight -= ADD_BUTTON_HEIGHT;
  }

  if (isHelpTextVisible) {
    maxHeight -= HELP_TEXT_HEIGHT;
  }

  if (mobile) {
    maxHeight = maxHeight - 120;
  } else {
    maxHeight = maxHeight - DIALOG_VERTICAL_MARGIN * 2;
  }

  return Math.min(
    maxHeight,
    Math.max(searching ? NO_RESULTS_HEIGHT : 0, rowCount * ROW_HEIGHT)
  );
};

export interface ValuesSectionProps {
  errors: ReadonlyMap<EntityId, TranslateableMessage>;
  values: ReadonlyEntityList<UpsertableLabelValue>;
  disabled: boolean;
  searching: boolean;
  onAddClick(): void;
  onValueChange(value: UpsertableLabelValue): void;
  onRemoveClick(labelValueId: EntityId): void;
}

export function ValuesSection({
  errors,
  values,
  disabled,
  searching,
  onAddClick,
  onValueChange,
  onRemoveClick,
}: ValuesSectionProps): ReactElement {
  const id = useIdPrefix();
  const mobile = useAppSelector(isMobile);
  const isHelpTextVisible = !searching && !values.find(({ name }) => name);

  const [size, setSize] = useState(() => {
    const width = mobile
      ? window.innerWidth - DIALOG_PADDING
      : (DIALOG_WIDTH - DIALOG_PADDING * 3) / 2;

    return {
      height: getHeight({
        mobile,
        searching,
        rowCount: values.length,
        isHelpTextVisible,
      }),
      width,
    };
  });

  const ref = useResizeObserver(
    useCallback(
      (entry) => {
        const { width } = entry.contentRect;

        setSize({
          height: getHeight({
            mobile,
            searching,
            rowCount: values.length,
            isHelpTextVisible,
          }),
          width,
        });
      },
      [isHelpTextVisible, mobile, searching, values.length]
    )
  );

  return (
    <div ref={ref} className={styles.padded}>
      <Heading messageId="Values" className={styles.heading} component="div" />
      <Values
        {...size}
        errors={errors}
        values={values}
        disabled={disabled}
        searching={searching}
        onAddClick={onAddClick}
        onValueChange={onValueChange}
        onRemoveClick={onRemoveClick}
      />
      {!searching && (
        <FlatButton
          id={`${id}-add-value`}
          onClick={onAddClick}
          theme="purple"
          className={styles.marginTop}
        >
          <PlusCircleOutlineIcon />
          <FormattedMessage messageId="Add" />
        </FlatButton>
      )}
      {isHelpTextVisible && (
        <HelpText
          messageId="SetInitialLabelValues"
          className={styles.marginTop}
        />
      )}
    </div>
  );
}
