import cn from 'classnames';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { AlertIcon, useIntl } from 'fwi-fe-components';

import {
  alertsButtonClicked,
  isAlertsAccessible,
  isMobile,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import { ALERTS } from 'utils/routes';

import styles from './AlertsLink.module.scss';

export default function AlertsLink(): ReactElement | null {
  const intl = useIntl();
  const mobile = useAppSelector(isMobile);
  const accessible = useAppSelector(isAlertsAccessible);
  const dispatch = useAppDispatch();

  if (!accessible) {
    return null;
  }

  return (
    <Link
      aria-label={intl.formatMessage({ id: 'Alerts' })}
      id="alerts-module-link"
      to={ALERTS}
      onClick={() => {
        dispatch(alertsButtonClicked());
      }}
      className={cn(styles.link, !mobile && styles.linkDesktop)}
    >
      <AlertIcon />
    </Link>
  );
}
